import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Conference – Transformation of letter press",
  "author": "Stefan Huber",
  "date": "2018-11-16",
  "layout": "post",
  "draft": false,
  "path": "/posts/transformation-mainz-2018/",
  "tags": ["TagOne", "TagTwo"],
  "description": "The Gutenberg-Museum in Mainz invited a broad audience of book lovers and type enthusiasts to a conference about haptics."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This weekend was the second run of the conference `}<a parentName="p" {...{
        "href": "https://web.archive.org/web/20181118222128/https://www.interdisciplinary-laboratory.hu-berlin.de/de/content/transformationen-des-buchdrucks-2-haptiken/?content_type=mitglied"
      }}>{`«Transformationen des Buchdrucks»`}</a>{` (Transformation of letter press) with the topic «Haptiken» (haptics). Where as the first conference two years ago was held in Berlin this time the `}<a parentName="p" {...{
        "href": "http://www.gutenberg-museum.de/"
      }}>{`«Gutenberg-Museum Mainz»`}</a>{` opened it's doors.`}</p>
    <p>{`There were many people from all over Germany and even some international Guests. The broad range of Speakers gave a good overview of several different Topics right now happening in the field of letter press.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAYCAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgD/2gAMAwEAAhADEAAAAYZ+haEvDER//8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECAxMREv/aAAgBAQABBQLDqdDJJp71kXCw8I//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQMBAT8BxH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAEQEhAgMi/9oACAEBAAY/AnmUKTtwPXJaP//EABsQAQACAgMAAAAAAAAAAAAAAAEAMRFRQWGR/9oACAEBAAE/ITReAhWgaiNMMTp0YqGOQGyL08T/2gAMAwEAAgADAAAAEL8//8QAFhEBAQEAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EAWx/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/ELqv/8QAHRABAAMAAQUAAAAAAAAAAAAAAQARIUExUWGBof/aAAgBAQABPxAE0OcpjCtvFb9lOA4WaCfQFweKYfHAilPuUlhO9sn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "conference impressions",
          "title": "conference impressions",
          "src": "/static/b286476498834a9b4b3fe05849bb4693/d9c39/IMG_8644.jpg",
          "srcSet": ["/static/b286476498834a9b4b3fe05849bb4693/20e5d/IMG_8644.jpg 450w", "/static/b286476498834a9b4b3fe05849bb4693/8e1fc/IMG_8644.jpg 900w", "/static/b286476498834a9b4b3fe05849bb4693/d9c39/IMG_8644.jpg 1800w", "/static/b286476498834a9b4b3fe05849bb4693/e3cde/IMG_8644.jpg 2700w", "/static/b286476498834a9b4b3fe05849bb4693/b0b73/IMG_8644.jpg 3600w", "/static/b286476498834a9b4b3fe05849bb4693/d2602/IMG_8644.jpg 4032w"],
          "sizes": "(max-width: 1800px) 100vw, 1800px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
Daniel Klotz of the company `}<a parentName="p" {...{
        "href": "https://www.lettertypen.de/"
      }}>{`Lettertypen`}</a>{` showed us during a break an output of his 1924 built press (Johannisberger Schnellpresse).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.11111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABnsZRKxDCz//EABsQAAIDAAMAAAAAAAAAAAAAAAECAAMREyEy/9oACAEBAAEFAlpLIen5UlAbPUJq3//EABcRAAMBAAAAAAAAAAAAAAAAAAABEiH/2gAIAQMBAT8BWko//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BV//EABsQAAICAwEAAAAAAAAAAAAAAAABETECECFi/9oACAEBAAY/ApXWYSqRZ512z//EABwQAQACAgMBAAAAAAAAAAAAAAEAESFhMVFxgf/aAAgBAQABPyGgLZ5UCxqYxDQ+QYXlAMUKO81LKu1E/9oADAMBAAIAAwAAABDjH//EABYRAQEBAAAAAAAAAAAAAAAAAAEAUf/aAAgBAwEBPxAMdL//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EGppR//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQXGRwf/aAAgBAQABPxCwQ0Roa5G98S9KBh3iUD1KPkqhT2mnMsl1QfWBwVDWEn//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "conference impressions",
          "title": "conference impressions",
          "src": "/static/1c8ec5ebe4b7ac372b55cd033b1e6f04/d9c39/IMG_8613.jpg",
          "srcSet": ["/static/1c8ec5ebe4b7ac372b55cd033b1e6f04/20e5d/IMG_8613.jpg 450w", "/static/1c8ec5ebe4b7ac372b55cd033b1e6f04/8e1fc/IMG_8613.jpg 900w", "/static/1c8ec5ebe4b7ac372b55cd033b1e6f04/d9c39/IMG_8613.jpg 1800w", "/static/1c8ec5ebe4b7ac372b55cd033b1e6f04/e3cde/IMG_8613.jpg 2700w", "/static/1c8ec5ebe4b7ac372b55cd033b1e6f04/b0b73/IMG_8613.jpg 3600w", "/static/1c8ec5ebe4b7ac372b55cd033b1e6f04/d2602/IMG_8613.jpg 4032w"],
          "sizes": "(max-width: 1800px) 100vw, 1800px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>{`
`}<a parentName="p" {...{
        "href": "https://www.babyinktwice.ch/"
      }}>{`Dafi Kühne`}</a>{` after his talk showed the originals produced in his workshop. He also showed us his new Video-Series the `}<a parentName="p" {...{
        "href": "https://vimeo.com/channels/printingshow"
      }}>{`Printing Show™`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      